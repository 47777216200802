module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-124001070-1","head":false,"anonymize":true,"respectDNT":false,"exclude":[],"variationId":"0","sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"spectralcompute.co.uk","pageTransitionDelay":0},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
