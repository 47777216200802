// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ai-tsx": () => import("./../../../src/pages/ai.tsx" /* webpackChunkName: "component---src-pages-ai-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-regex-tsx": () => import("./../../../src/pages/regex.tsx" /* webpackChunkName: "component---src-pages-regex-tsx" */),
  "component---src-templates-markdown-template-tsx": () => import("./../../../src/templates/markdownTemplate.tsx" /* webpackChunkName: "component---src-templates-markdown-template-tsx" */)
}

